import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    Button,
    Flex,
    Icon,
    useColorModeValue,
    useBreakpointValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FaLaptopCode, FaRocket, FaChartLine, FaHandshake } from 'react-icons/fa';

const features = [
    { icon: FaLaptopCode, text: "Création de sites web sur mesure" },
    { icon: FaRocket, text: "Stratégies digitales innovantes" },
    { icon: FaChartLine, text: "Optimisation SEO avancée" },
    { icon: FaHandshake, text: "Accompagnement personnalisé" },
];

const CompanySection = () => {
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const bg = useColorModeValue('gray.50', 'gray.900');
    const cardBg = useColorModeValue('white', 'gray.700'); // Définition au niveau supérieur
    const btnSize = useBreakpointValue({ base: 'md', md: 'lg' });

    return (
        <Box as="section" py={{ base: '20', md: '160px' }} bg={bg} position="relative" overflow="hidden">
            <Container maxW="7xl" textAlign="center" zIndex={1}>
                <Heading as="h2" fontSize={{ base: '3xl', md: '4xl' }} fontWeight="bold" color={headingColor} mb={6} px={{ base: '12', md: '0' }}>
                    Bienvenue chez Rock Your Net
                </Heading>
                <Text fontSize="lg" color={textColor} mb={8} maxW="4xl" mx="auto">
                    Chez Rock Your Net, nous combinons créativité et expertise technique pour vous offrir des solutions digitales adaptées à votre entreprise.
                    Que vous ayez besoin d'un site vitrine, d'une boutique en ligne, ou d'une application web sur mesure, notre équipe est à vos côtés pour concrétiser vos projets et améliorer votre visibilité en ligne.
                </Text>

                {/* Feature Icons */}
                <Flex
                    justify="center"
                    wrap="wrap"
                    mb={12}
                    gap={6}  // Ajoute de l'espace entre les cartes
                >
                    {features.map((feature, index) => (
                        <Flex
                            key={index}
                            direction="column"
                            align="center"
                            p={6}
                            bg={cardBg}
                            shadow="lg"
                            borderRadius="lg"
                            minW={{ base: '200px', md: '240px' }}  // Largeur minimale responsive
                            maxW="240px"  // Largeur maximale pour toutes les cartes
                            h="160px"  // Hauteur fixe pour uniformiser les cartes
                            transition="all 0.3s"
                            _hover={{ transform: 'scale(1.05)', shadow: 'xl' }}
                        >
                            <Icon as={feature.icon} w={12} h={12} color="purple.500" mb={4} />
                            <Text fontSize="md" color={headingColor} fontWeight="semibold" textAlign="center">
                                {feature.text}
                            </Text>
                        </Flex>
                    ))}
                </Flex>

                {/* Call to action */}
                <Flex justify="center">
                    <Button
                        as={RouterLink}
                        to="/A-propos"
                        colorScheme="purple"
                        size={btnSize}
                        px={8}
                        py={6}
                        fontSize="lg"
                        transition="all 0.3s"
                        _hover={{
                            bg: 'white',
                            color: 'purple.600',
                            border: '1px solid',
                            borderColor: 'purple.600',
                        }}
                    >
                        En savoir plus sur nous
                    </Button>
                </Flex>
            </Container>
        </Box>
    );
};

export default CompanySection;
