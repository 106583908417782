import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    SimpleGrid,
    Icon,
    Stack,
    Flex,
    Button,
    useColorModeValue,
    Image
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import SEOImage from '../imagesComponents/seo.jpg'; // Image SEO personnalisée
import Logo from '../imagesComponents/Logo_RockYourNet650.webp';
import { Link as RouterLink } from 'react-router-dom';



// Fonctionnalités du service SEO
const features = [
    { id: 1, title: "Analyse de performance SEO", description: "Évaluez les performances actuelles de votre site et identifiez les axes d'amélioration." },
    { id: 2, title: "Optimisation de contenu", description: "Optimisez vos textes et balises pour mieux répondre aux attentes de Google et augmenter votre visibilité." },
    { id: 3, title: "Référencement local", description: "Positionnez-vous dans les résultats de recherche géolocalisés pour attirer une clientèle locale." },
    { id: 4, title: "Stratégie de backlinks", description: "Boostez votre autorité en ligne avec une stratégie de création de liens efficaces et pertinents." },
];

export default function ReferencementSEO() {
    // Déplacement des hooks React à l'extérieur de tout callback
    const bg = useColorModeValue('gray.50', 'gray.900');
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const cardBg = useColorModeValue('white', 'gray.700');

    return (
        <Box as="section" py={10} bg={bg} minH="100vh">
            {/* Hero section */}
            <Container maxW="7xl" py={12}>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} alignItems="center">
                    <Stack spacing={6}>
                        <Heading
                            lineHeight={1.2}
                            fontWeight="bold"
                            fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
                            color={headingColor}
                        >
                            Optimisez votre présence en ligne avec <br />
                            <Flex justifyContent="center" alignItems="center">
                                <Image src={Logo} alt="Logo Rock Your Net" width={{ base: '150px', md: '280px' }} mt={5} ml={3} />
                            </Flex>

                        </Heading>
                        <Text color={textColor} fontSize="lg">
                            Notre expertise en SEO vous permet d'améliorer votre visibilité
                            sur les moteurs de recherche. Maximisez vos chances d'apparaître en haut des résultats et de générer du trafic sur votre site.
                        </Text>
                        <Flex justifyContent="center" alignItems="center">
                            <Button
                                as={RouterLink}  // Utilisez Link de react-router-dom
                                to="/Contact"  // Spécifiez la route vers laquelle vous voulez rediriger
                                colorScheme="purple"
                                size="lg"
                                width="200px"
                                mb={10}
                                _hover={{
                                    bg: 'white',
                                    color: 'purple.600',
                                    border: '1px solid',
                                    borderColor: 'purple.600',
                                }}
                            >
                                Obtenez un devis
                            </Button>
                        </Flex>
                    </Stack>
                    <Flex justifyContent="center" alignItems="center">
                        <Image
                            src={SEOImage}
                            alt="Référencement SEO"
                            boxSize={{ base: '300px', md: '450px' }}
                            objectFit="cover"
                            borderRadius="md"
                            shadow="lg"
                        />
                    </Flex>
                </SimpleGrid>
            </Container>

            {/* Section des fonctionnalités SEO */}
            <Container maxW="7xl" py={12}>
                <Heading as="h2" fontSize="3xl" mb={10} textAlign="center" color={headingColor}>
                    Nos Services de Référencement SEO
                </Heading>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
                    {features.map(feature => (
                        <Box
                            key={feature.id}
                            p={6}
                            bg={cardBg}
                            borderRadius="lg"
                            shadow="lg"
                            _hover={{ transform: 'scale(1.05)', transition: '0.3s ease-in-out' }}
                        >
                            <Flex align="center" mb={4}>
                                <Icon as={CheckIcon} color="purple.500" boxSize={6} mr={2} />
                                <Heading as="h3" fontSize="xl" color={headingColor}>
                                    {feature.title}
                                </Heading>
                            </Flex>
                            <Text color={textColor}>
                                {feature.description}
                            </Text>
                        </Box>
                    ))}
                </SimpleGrid>
            </Container>

            {/* Appel à l'action */}
            <Container maxW="7xl" py={12} textAlign="center">
                <Heading as="h3" fontSize="2xl" color={headingColor} mb={4}>
                    Prêt à améliorer votre visibilité sur le web ?
                </Heading>
                <Button
                    as={RouterLink}  // Utilisez Link de react-router-dom
                    to="/Contact"  // Spécifiez la route vers laquelle vous voulez rediriger
                    colorScheme="purple"
                    size="lg"
                    mb={10}
                    _hover={{
                        bg: 'white',
                        color: 'purple.600',
                        border: '1px solid',
                        borderColor: 'purple.600',
                    }}
                >
                    Nous contacter
                </Button>
            </Container>
        </Box>
    );
}
