import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    Image,
    SimpleGrid,
    Flex,
    Button,
    List,
    ListItem,
    ListIcon,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    useColorModeValue,
    Link,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import AppImage from '../imagesComponents/GarageAdmin.jpg'; // Remplacer par une vraie image

const ProductPage = () => {
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const listColor = useColorModeValue('gray.600', 'white');
    const bg = useColorModeValue('gray.50', 'gray.900');
    const cardBg = useColorModeValue('white', 'gray.700');

    return (
        <Box as="section" py={12} bg={bg} minH="100vh">
            {/* Section principale */}
            <Container maxW="7xl" textAlign="center" mt={{ base: '0', md: '16' }} mb={{ base: '16', md: '32' }}>
                <Heading as="h1" fontSize={{ base: '3xl', md: '4xl' }} fontWeight="bold" color={headingColor}>
                    Notre Application Web pour Garages et Concessionnaires
                </Heading>
                <Text fontSize="lg" color={textColor} mt={4}>
                    Gérez votre site web, présentez vos services et vos véhicules d'occasion grâce à une application simple, intuitive et performante.
                </Text>
            </Container>

            {/* Grille des fonctionnalités */}
            <Container maxW="7xl">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={12}>
                    {/* Section texte */}
                    <Flex direction="column" justifyContent="center">
                        <Box textAlign={{ base: 'center', md: 'left' }}>
                            <Heading as="h2" fontSize="2xl" mb={4} color={headingColor}>
                                Une solution complète et intuitive pour les professionnels de l'automobile
                            </Heading>
                            <Text color={textColor} mb={6}>
                                Notre application web vous permet de gérer facilement votre site internet, d'ajouter des véhicules d'occasion, de présenter vos services, et bien plus encore. Vous pouvez modifier l'intégralité de votre site via une interface simple et intuitive, sans besoin de compétences techniques.
                            </Text>
                            <List spacing={3} mb={6} color={listColor}>
                                <ListItem>
                                    <ListIcon as={CheckIcon} color="purple.500" />
                                    Interface simple et intuitive pour modifier votre site
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckIcon} color="purple.500" />
                                    Gestion complète des véhicules d'occasion
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckIcon} color="purple.500" />
                                    Présentation de vos différents services
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckIcon} color="purple.500" />
                                    Accès multi-utilisateurs avec gestion des rôles
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckIcon} color="purple.500" />
                                    Gestion des témoignages clients et leur validation
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckIcon} color="purple.500" />
                                    Personnalisation du logo, des couleurs et des images du site
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckIcon} color="purple.500" />
                                    Optimisation SEO pour un meilleur référencement
                                </ListItem>
                            </List>
                            <Flex justifyContent={{ base: 'center', md: 'flex-start' }}>
                                <Button
                                    as={RouterLink}
                                    to="/contact"
                                    colorScheme="purple"
                                    size="lg"
                                    _hover={{
                                        bg: 'white',
                                        color: 'purple.600',
                                        border: '1px solid',
                                        borderColor: 'purple.600',
                                    }}
                                >
                                    Demander une Démo
                                </Button>
                            </Flex>
                        </Box>
                    </Flex>

                    {/* Image illustrative */}
                    <Flex justifyContent={{ base: 'center', md: 'flex-start' }}>
                        <Image
                            src={AppImage} // Remplacez par le lien de l'image réelle
                            alt="Application de gestion pour garages et concessionnaires"
                            borderRadius="md"
                            boxShadow="lg"
                            maxW="100%"
                            height="auto"
                            objectFit="cover"
                        />
                    </Flex>
                </SimpleGrid>
            </Container>

            {/* Section supplémentaire */}
            <Container maxW="7xl" mt={{ base: '24', md: '32' }} mb={{ base: '16', md: '24' }}>
                <Heading as="h2" fontSize="2xl" mb={6} textAlign="center" color={headingColor}>
                    Pourquoi choisir notre solution ?
                </Heading>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                    <Box bg={cardBg} borderRadius="lg" shadow="lg" p={6}>
                        <Heading as="h3" fontSize="xl" mb={4} color={headingColor}>
                            Simplicité et rapidité
                        </Heading>
                        <Text color={textColor}>
                            Notre application est conçue pour être accessible à tous, sans avoir besoin de connaissances techniques. Vous pouvez gérer vos véhicules, vos services et bien plus en seulement quelques clics.
                        </Text>
                    </Box>
                    <Box bg={cardBg} borderRadius="lg" shadow="lg" p={6}>
                        <Heading as="h3" fontSize="xl" mb={4} color={headingColor}>
                            Personnalisation totale
                        </Heading>
                        <Text color={textColor}>
                            Grâce à une interface intuitive, vous pouvez personnaliser l'apparence de votre site en changeant le logo, les couleurs, et les images. Ajoutez vos témoignages clients et validez ceux que vous souhaitez mettre en avant sur la page d'accueil.
                        </Text>
                    </Box>
                </SimpleGrid>
            </Container>

            {/* Lien vers le site fictif */}
            <Container maxW="7xl" textAlign="center" mt={28} mb={28}>
                <Heading as="h2" fontSize="2xl" mb={6} color={headingColor}>
                    Découvrez l'application en action
                </Heading>
                <Text fontSize="lg" color={textColor} mb={6}>
                    Visitez notre garage fictif pour explorer toutes les fonctionnalités de l'application.
                </Text>
                <Button
                    as={Link}
                    href="https://garage-parrot.eu"
                    isExternal
                    colorScheme="purple"
                    size="lg"
                    _hover={{
                        bg: 'white',
                        color: 'purple.600',
                        border: '1px solid',
                        borderColor: 'purple.600',
                    }}
                >
                    Découvrir
                </Button>
            </Container>

            {/* FAQ Section */}
            <Container maxW="7xl" mt={16} mb={12}>
                <Heading as="h2" fontSize="2xl" mb={6} textAlign="center" color={headingColor}>
                    Foire Aux Questions
                </Heading>
                <Accordion allowToggle>
                    <AccordionItem>
                        <h2>
                            <AccordionButton color={listColor}>
                                <Box flex="1" textAlign="left" color={headingColor}>
                                    Quelles sont les fonctionnalités incluses dans l'application ?
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} color={textColor}>
                            L'application inclut la gestion des véhicules d'occasion, la présentation de vos services, l'optimisation SEO, la gestion multi-utilisateurs, la personnalisation du site, et la validation des témoignages clients.
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <h2>
                            <AccordionButton color={listColor}>
                                <Box flex="1" textAlign="left" color={headingColor}>
                                    Puis-je personnaliser le design de mon site ?
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} color={textColor}>
                            Oui, vous pouvez personnaliser le logo, les couleurs, les images, et ajouter vos propres contenus pour rendre votre site unique.
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <h2>
                            <AccordionButton color={listColor}>
                                <Box flex="1" textAlign="left" color={headingColor}>
                                    Puis-je ajouter plusieurs utilisateurs avec des rôles différents ?
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} color={textColor}>
                            Oui, vous pouvez créer des comptes pour vos employés et leur donner accès à certaines fonctionnalités spécifiques, comme répondre aux messages clients ou ajouter de nouveaux véhicules.
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <h2>
                            <AccordionButton color={listColor}>
                                <Box flex="1" textAlign="left" color={headingColor}>
                                    Comment puis-je tester l'application ?
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} color={textColor}>
                            Vous pouvez demander une démo ou visiter notre site fictif Garage Parrot pour explorer toutes les fonctionnalités.
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Container>
        </Box>
    );
};

export default ProductPage;
