import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    Image,
    Flex,
    Button,
    useColorModeValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import Vitrine from '../imagesComponents/vitrine.webp';
import Ecommerce from '../imagesComponents/e-commerce.webp';
import SEO from '../imagesComponents/seo.jpg';
import Catalogue from '../imagesComponents/catalogue.webp';
import ApplicationWeb from '../imagesComponents/applicationWeb.webp';

// Informations des services avec descriptions développées
const services = [
    {
        title: "Création de sites vitrines",
        description: "Nous concevons des sites vitrines modernes et attrayants qui captent l’attention de vos visiteurs. Grâce à un design personnalisé et une ergonomie soignée, votre site sera optimisé pour représenter votre activité de manière professionnelle. Que ce soit pour promouvoir vos services ou votre marque, un site vitrine est essentiel pour attirer des clients potentiels.",
        link: "/Services/Site-vitrine",
        image: Vitrine,
    },
    {
        title: "Création de sites catalogue",
        description: "Un site catalogue est la solution idéale pour les entreprises souhaitant présenter une large gamme de produits sans fonctionnalité de vente en ligne. Nous développons des plateformes optimisées pour l’affichage de catalogues complets, offrant une navigation fluide et une gestion simplifiée pour vos produits. Montrez à vos clients tout ce que vous avez à offrir avec un site catalogue clair et fonctionnel.",
        link: "/Services/Site-catalogue",
        image: Catalogue,
    },
    {
        title: "Boutiques en ligne",
        description: "Avec nos solutions de sites e-commerce, nous vous aidons à vendre vos produits en ligne avec simplicité et efficacité. Nos boutiques en ligne sont pensées pour maximiser l’expérience utilisateur et augmenter vos conversions. Grâce à une gestion facile des produits, des paiements sécurisés et un design réactif, vous pouvez atteindre une clientèle plus large et booster vos ventes.",
        link: "/Services/Site-e-commerce",
        image: Ecommerce,
    },
    {
        title: "Référencement SEO",
        description: "Un bon référencement SEO est essentiel pour assurer la visibilité de votre site sur les moteurs de recherche. Chez Rock Your Net, nous mettons en place des stratégies SEO efficaces pour améliorer votre positionnement sur Google, attirer un trafic qualifié et augmenter votre audience. De l’audit technique à la création de contenu optimisé, nous vous aidons à renforcer votre présence en ligne.",
        link: "/Services/Referencement",
        image: SEO,
    },
    {
        title: "Création d'applications Web",
        description: "Nous développons des applications web et mobiles sur mesure, adaptées aux besoins spécifiques de votre entreprise. Que vous souhaitiez une application performante pour gérer vos processus internes ou une solution mobile pour interagir avec vos clients, nous utilisons les technologies les plus récentes pour garantir performance et sécurité.",
        link: "/Services/ApplicationWeb",
        image: ApplicationWeb,
    },
];

export default function ServicesPage() {
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const cardBg = useColorModeValue('white', 'gray.700');
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <Box as="section" py={12} bg={bg} minH="100vh">
            {/* Hero Section */}
            <Container maxW="7xl" textAlign="center" mb={12} py={{ base: '4', lg: '10' }}>
                <Heading as="h1" fontSize={{ base: '3xl', md: '4xl' }} fontWeight="bold" color={headingColor}>
                    Nos Services
                </Heading>
                <Text fontSize="lg" color={textColor} mt={4}>
                    Découvrez nos services conçus pour développer votre présence en ligne et optimiser votre impact digital. Que vous ayez besoin d'un site web, d'une application ou de solutions SEO, nous avons ce qu'il vous faut.
                </Text>
            </Container>

            {/* Grille de services sous forme de row */}
            <Container maxW="7xl">
                {services.map((service, index) => (
                    <Flex
                        key={index}
                        bg={cardBg}
                        borderRadius="lg"
                        shadow="lg"
                        p={6}
                        mb={10}
                        direction={{ base: 'column', md: 'row' }}
                        transition="0.3s ease-in-out"
                        _hover={{ transform: 'scale(1.05)' }}
                        alignItems="center"
                    >
                        {/* Image du service */}
                        <Box flexShrink={0} mb={{ base: 4, md: 0 }} mr={{ md: 6 }}>
                            <Image
                                src={service.image}
                                alt={service.title}
                                borderRadius="md"
                                width={{ base: '100%', md: '300px' }}
                                height={{ base: 'auto', md: '200px' }}
                                objectFit="cover"
                                loading="lazy"  // Ajout de lazy loading

                            />
                        </Box>

                        {/* Texte du service */}
                        <Box flex={1}>
                            <Heading as="h3" fontSize="2xl" mb={4} fontWeight="bold" color={headingColor}>
                                {service.title}
                            </Heading>
                            <Text color={textColor} mb={6}>
                                {service.description}
                            </Text>
                            <Flex justifyContent={{ base: 'center', md: 'flex-start' }}>
                                <Button
                                    as={RouterLink}
                                    to={service.link}
                                    colorScheme="purple"
                                    variant="solid"
                                    _hover={{
                                        bg: 'white',
                                        color: 'purple.600',
                                        border: '1px solid',
                                        borderColor: 'purple.600',
                                    }}
                                >
                                    Découvrir
                                </Button>
                            </Flex>
                        </Box>
                    </Flex>
                ))}
            </Container>

            {/* Call to action */}
            <Container maxW="7xl" textAlign="center" mt={12} py={{ base: '20', md: '80px' }}>
                <Heading as="h3" fontSize="2xl" color={headingColor} mb={4}>
                    Prêt à démarrer votre projet ?
                </Heading>
                <Button
                    as={RouterLink}  // Utilisez Link de react-router-dom
                    to="/Contact"  // Spécifiez la route vers laquelle vous voulez rediriger
                    colorScheme="purple"
                    size="lg"
                    _hover={{
                        bg: 'white',
                        color: 'purple.600',
                        border: '1px solid',
                        borderColor: 'purple.600',
                    }}
                >
                    Nous contacter
                </Button>
            </Container>
        </Box>
    );
}
