import React from 'react';
import {
    Box,
    Container,
    Heading,
    SimpleGrid,
    Text,
    Image,
    useColorMode,
    useColorModeValue,
} from '@chakra-ui/react';
import Clients from '../imagesComponents/clients.jpg';
import Cappadoce from '../imagesComponents/cappadoce.png';
import Rhoneau from '../imagesComponents/rhoneau.jpg';
import Massis from '../imagesComponents/Massis.jpg';

// Section Témoignages
const TestimonialsSection = () => {

    const headingColor = useColorModeValue('gray.800', 'white');

    return (
        <Box
            as="section"
            py={{ base: '20', md: '160px' }}
            bg={useColorModeValue('gray.100', 'gray.900')}
            backgroundImage={`url(${Clients})`}  // Ajout de l'image en fond
            backgroundSize="cover"  // Assure que l'image couvre toute la section
            backgroundPosition="center"  // Centre l'image
            backgroundRepeat="no-repeat"  // Empêche la répétition
            position="relative"
            _before={{
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: useColorModeValue(
                    'rgba(221, 204, 229, 0.9)',
                    'rgba(36, 9, 48, 0.7)'
                ), // Superposition selon le mode clair ou sombre
                filter: 'grayscale(80%)', // Application du filtre noir et blanc

                zIndex: 1, // Assure que l'overlay est derrière le contenu
            }}
        >
            {/* Contenu positionné au-dessus de l'overlay */}
            <Container maxW="7xl" position="relative" zIndex={2}>
                <Heading
                    as="h2"
                    position="relative"
                    zIndex={2}
                    textAlign="center"
                    fontSize={{ base: '3xl', md: '4xl' }}
                    mb={10}
                    fontWeight="bold"
                    color={headingColor}

                >
                    Ce que disent nos clients
                </Heading>
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} mx={10}>
                    <Testimonial
                        name="Restaurant Cappadoce"
                        feedback="Un site vitrine superbe et un support client irréprochable. Merci à toute l'équipe !"
                        image={Cappadoce}
                    />
                    <Testimonial
                        name="Rhon'eau"
                        feedback="Notre activité a explosé grâce à leur expertise en création de site internet. Professionnel et réactif. "
                        image={Rhoneau}
                    />
                    <Testimonial
                        name="Massis Club d'Echecs"
                        feedback="RockYourNet a transformé notre présence en ligne grâce à un référencement exceptionnel !"
                        image={Massis}
                    />
                </SimpleGrid>
            </Container>
        </Box>
    );
};

// Témoignage Card avec image, nom et feedback
const Testimonial = ({ name, feedback, image }) => {

    const { colorMode } = useColorMode();
    return (
        <Box
            p={8}
            shadow="lg"
            borderRadius="lg"
            bg={useColorModeValue('white', 'gray.700')}
            transition="0.3s ease-in-out"
            _hover={{ transform: 'scale(1.05)' }} // Animation au hover
        >
            {/* Image en haut */}
            <Image
                src={image}
                alt={name}
                w="120px"
                h="120px"
                objectFit="contain"
                mx="auto" // Centre l'image horizontalement
                mb={4}
                loading="lazy"  // Ajout de lazy loading

            />
            {/* Feedback */}
            <Text fontSize="lg" mb={4} color={useColorModeValue('gray.600', 'gray.300')}>
                {feedback}
            </Text>
            {/* Nom */}
            <Text fontWeight="bold" fontSize="lg" color={colorMode === "dark" ? "#d8bfe3" : "purple.600"} textAlign="center">
                {name}
            </Text>
        </Box>
    );
};

export default TestimonialsSection;
