import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import theme from "./theme";
import Accueil from "./components/pages/Accueil";
import Services from "./components/pages/Services.jsx";
import Créations from "./components/pages/Créations.jsx";
import SiteVitrine from "./components/pages/SiteVitrine.jsx";
import SiteCatalogue from "./components/pages/SiteCatalogue.jsx";
import SiteECommerce from "./components/pages/SiteECommerce.jsx";
import Referencement from "./components/pages/Referencement";
import Application from "./components/pages/ApplicationWeb.jsx";
import Contact from "./components/pages/Contact";
import APropos from "./components/pages/APropos";
import Produits from "./components/pages/Produits";
import MentionsLégales from "./components/pages/MentionsLégales";
import ScrollToTop from "./components/common/ScrollToTop";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Services/Site-vitrine" element={<SiteVitrine />} />
          <Route path="/Services/Site-catalogue" element={<SiteCatalogue />} />
          <Route path="/Services/Site-e-commerce" element={<SiteECommerce />} />
          <Route path="/Services/Referencement" element={<Referencement />} />
          <Route path="/Services/ApplicationWeb" element={<Application />} />
          <Route path="/Créations" element={<Créations />} />
          <Route path="/Produits" element={<Produits />} />
          <Route path="/A-propos" element={<APropos />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Mentions-Légales" element={<MentionsLégales />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
