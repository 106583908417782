import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../common/Navbar';
import MainMentionsLégales from '../sections/MainMentionsLégales';
import Footer from '../common/Footer';

export default function TestPage() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>


            {/* Structure de la page */}
            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr auto'}
                gridTemplateColumns='1fr'
                minHeight="100vh" // Change from h="100vh" to minHeight for flexibility
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                {/* Header (fixe avec Navbar) */}
                <GridItem area={'header'} position="fixed" zIndex={3} w="100%">
                    <Navbar />
                </GridItem>

                {/* Section principale */}
                <GridItem area={'main'} pt={{ base: '80px', md: '94px' }} pb={8}>
                    <MainMentionsLégales />
                </GridItem>

                {/* Footer */}
                <GridItem area={'footer'} w="100%">
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};
