import React from 'react';
import {
    Box,
    Button,
    Container,
    Heading,
    Text,
    Image,
    Flex,
    useTheme,
    useColorMode,
    useColorModeValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import Vitrine from '../imagesComponents/vitrine.webp';
import Ecommerce from '../imagesComponents/e-commerce.webp';
import SEO from '../imagesComponents/seo.jpg';
import Catalogue from '../imagesComponents/catalogue.webp';
import ApplicationWeb from '../imagesComponents/applicationWeb.webp';

// Section Services
const ServicesSection = () => {
    const headingColor = useColorModeValue('gray.800', 'white');


    return (
        <Box as="section" py={{ base: '20', md: '160px' }} bg={useColorModeValue('white', 'gray.800')}>
            <Container maxW="7xl">
                <Heading
                    as="h2"
                    textAlign="center"
                    fontSize={{ base: '3xl', md: '4xl' }}
                    mb={10}
                    fontWeight="bold"
                    color={headingColor}
                >
                    Nos Services
                </Heading>

                {/* Première ligne avec trois services */}
                <Flex wrap="wrap" justifyContent="space-between" mb={3}>
                    <ServiceCard
                        title="Création de sites vitrines"
                        description="Votre site professionnel, moderne et attractif pour représenter votre entreprise en ligne."
                        link="/Services/Site-vitrine"
                        image={Vitrine}
                    />
                    <ServiceCard
                        title="Création de sites catalogue"
                        description="Idéal pour les entreprises souhaitant présenter un grand catalogue de produits sans fonctionnalité e-commerce."
                        link="/services/Site-catalogue"
                        image={Catalogue}
                    />
                    <ServiceCard
                        title="Boutiques en ligne"
                        description="Nous construisons des sites e-commerce performants et simples à gérer pour la vente de vos produits en ligne."
                        link="/Services/Site-e-commerce"
                        image={Ecommerce}
                    />
                </Flex>

                {/* Deuxième ligne centrée */}
                <Flex wrap="wrap" justifyContent="center" gap={{ base: '2', md: '14' }}>
                    <ServiceCard
                        title="Référencement SEO"
                        description="Optimisez votre site pour un meilleur positionnement sur Google et augmenter votre nombre de visiteurs."
                        link="/Services/Referencement"
                        image={SEO}
                    />
                    <ServiceCard
                        title="Création d'applications Web"
                        description="Nous développons des applications web et mobiles adaptées à vos besoins spécifiques."
                        link="/Services/ApplicationWeb"
                        image={ApplicationWeb}
                    />
                </Flex>
            </Container>
        </Box>
    );
};

// Service Card avec image, titre et description
const ServiceCard = ({ title, description, link, image }) => {
    const { colorMode } = useColorMode();
    const theme = useTheme();

    return (
        <Box
            maxW="380px"
            mb={10}
            p={6}
            shadow="lg"
            borderRadius="lg"
            bg={useColorModeValue('gray.50', 'gray.700')}
            transition="0.3s ease-in-out"
            _hover={{ transform: 'scale(1.05)' }} // Animation au hover
            mx={{ base: 'auto', md: '0' }} // Centre les cards sur petits écrans
        >
            <Image
                src={image}
                alt={title}
                borderRadius="md"
                mb={4}
                width="100%"
                height="250px"
                objectFit="cover"
                objectPosition="top"
                loading="lazy"  // Ajout de lazy loading

            />
            <Heading
                as="h3"
                fontSize="xl"
                mb={4}
                fontWeight="bold"
                color={colorMode === "dark" ? theme.colors.dark.bg : theme.colors.light.bg}
            >
                {title}
            </Heading>
            <Text color={useColorModeValue('gray.600', 'gray.300')} mb={6}>
                {description}
            </Text>
            <Flex justifyContent="center">
                <Button
                    as={RouterLink}
                    to={link}
                    colorScheme="purple"
                    variant="solid"
                    _hover={{
                        bg: 'white',            // Couleur de fond blanche au survol
                        color: 'purple.600',     // Texte de couleur purple.600 au survol
                        border: '1px solid',     // Ajoute une bordure
                        borderColor: 'purple.600', // Couleur de la bordure purple.600
                    }}
                >
                    Découvrir
                </Button>
            </Flex>
        </Box>
    );
};

export default ServicesSection;
