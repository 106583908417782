import React from 'react';
import {
    Box,
    Container,
    Text,
    useTheme,
    useColorMode,
} from '@chakra-ui/react';

// Footer
const Footer = () => {

    const { colorMode } = useColorMode();
    const theme = useTheme();
    return (
        <Box as="footer"
            py={8}
            bg={
                colorMode === "dark"
                    ? theme.colors.dark.color
                    : theme.colors.light.color
            }
            color={
                colorMode === "dark"
                    ? theme.colors.dark.bg
                    : theme.colors.light.bg
            }        >
            <Container maxW="7xl" textAlign="center">
                <Text fontSize="md">© {new Date().getFullYear()} RockYourNet. Tous droits réservés.</Text>
            </Container>
        </Box>
    );
};

export default Footer;