import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    Image,
    SimpleGrid,
    Flex,
    Button,
    useColorModeValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import Cappadoce from '../imagesComponents/CappadoceAccueil.jpg';
import Rhoneau from '../imagesComponents/RhoneauAccueil.jpg';
import UPG from '../imagesComponents/UPGAccueil.jpg';
import Massis from '../imagesComponents/MassisAccueil.jpg';
import Garage from '../imagesComponents/GarageAccueil.jpg';
import VGT from '../imagesComponents/VGTAccueil.jpg';


// Données des créations
const creations = [
    {
        title: "Very Good Trip",
        description: "Une application innovante utilisant l'IA pour créer des voyages sur mesure à la demande des utilisateurs, avec une interface utilisateur fluide et intuitive.",
        image: VGT, // Remplacer par une vraie image
        link: "https://verygoodtrip.org/",
    },
    {
        title: "Restaurant Cappadoce",
        description: "Un site vitrine moderne pour un restaurant turc à Auxerre, mettant en valeur ses spécialités culinaires avec un référencement SEO optimisé.",
        image: Cappadoce, // Remplacer par une vraie image
        link: "https://www.restaurant-cappadoce.com/",
    },
    {
        title: "Gestion de concessionnaires automobiles",
        description: "Création d'une application-web complète pour la gestion des sites internet des concessionnaires automobiles, intégrant des fonctionnalités avancées pour la gestion des véhicules et des services.",
        image: Garage, // Remplacer par une vraie image
        link: "https://garage-parrot.eu/accueil.php",
    },
    {
        title: "Massis Club d'Echecs",
        description: "Un site vitrine pour un club d'échecs à Lyon, offrant des fonctionnalités interactives pour les membres et un référencement SEO adapté au domaine sportif.",
        image: Massis, // Remplacer par une vraie image
        link: "https://www.massis-club-echecs.com/",
    },
    {
        title: "Rhôn'eau",
        description: "Un site vitrine pour un plombier à Lyon, conçu pour attirer plus de clients grâce à un design professionnel et un référencement SEO performant.",
        image: Rhoneau, // Remplacer par une vraie image
        link: "https://www.rhoneau.fr/",
    },
    {
        title: "Association Un Pas de Géant",
        description: "Un site vitrine dédié à une association qui aide les enfants handicapés, avec un design inclusif et un SEO adapté pour atteindre plus de bénévoles et de soutiens.",
        image: UPG, // Remplacer par une vraie image
        link: "https://www.unpasdegeant.com/",
    },



];

const CreationPage = () => {
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const cardBg = useColorModeValue('white', 'gray.700');

    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <Box as="section" py={12} bg={bg} minH="100vh">
            {/* Hero Section */}
            <Container maxW="7xl" textAlign="center" mt={{ base: '0', md: '10' }} mb={{ base: '16', md: '24' }}>
                <Heading as="h1" fontSize={{ base: '3xl', md: '4xl' }} fontWeight="bold" color={headingColor}>
                    Nos Créations
                </Heading>
                <Text fontSize="lg" color={textColor} mt={4}>
                    Découvrez nos dernières réalisations en matière de sites web, d'applications et de boutiques en ligne.
                </Text>
            </Container>

            {/* Grille des créations */}
            <Container maxW="7xl">
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
                    {creations.map((creation, index) => (
                        <Box
                            key={index}
                            bg={cardBg}
                            borderRadius="lg"
                            shadow="lg"
                            p={6}
                            transition="0.3s ease-in-out"
                            _hover={{ transform: 'scale(1.05)' }}
                            minHeight="450px"  // Définit une hauteur minimale uniforme pour toutes les cards
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                        >
                            <Image
                                src={creation.image}
                                alt={creation.title}
                                borderRadius="md"
                                mb={4}
                                width="100%"
                                height="230px"
                                objectFit="cover"
                                objectPosition="top"
                            />
                            <Box>
                                <Heading as="h3" fontSize="xl" mb={4} fontWeight="bold" color={headingColor}>
                                    {creation.title}
                                </Heading>
                                <Text color={textColor} mb={6}>
                                    {creation.description}
                                </Text>
                            </Box>
                            <Flex justifyContent="center">
                                <Button
                                    as="a"
                                    w="110px"
                                    href={creation.link}
                                    target="_blank"  // Ouvrir dans un nouvel onglet
                                    rel="noopener noreferrer"  // Sécurité
                                    colorScheme="purple"
                                    variant="solid"
                                    _hover={{
                                        bg: 'white',
                                        color: 'purple.600',
                                        border: '1px solid',
                                        borderColor: 'purple.600',
                                    }}
                                >
                                    Découvrir
                                </Button>

                            </Flex>
                        </Box>
                    ))}
                </SimpleGrid>
            </Container>

            {/* Call to action */}
            <Container maxW="7xl" textAlign="center" my={{ base: '16', md: '20' }} pt={16} >
                <Heading as="h3" fontSize="2xl" color={headingColor} mb={4}>
                    Vous avez un projet en tête ?
                </Heading>
                <Button
                    as={RouterLink}
                    to="/contact"
                    colorScheme="purple"
                    size="lg"
                    _hover={{
                        bg: 'white',
                        color: 'purple.600',
                        border: '1px solid',
                        borderColor: 'purple.600',
                    }}
                >
                    Contactez-nous pour une consultation
                </Button>
            </Container>
        </Box>
    );
};

export default CreationPage;
