import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../common/Navbar';
import MainCréations from '../sections/MainCréations';
import Footer from '../common/Footer';
import { Helmet } from 'react-helmet'; // Pour gérer les meta-données dans React

export default function TestPage() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                {/* Titre et Meta description */}
                <title>Nos Dernières Créations | Rock Your Net</title>
                <meta name="description" content="Découvrez les dernières créations de Rock Your Net, incluant des sites vitrines, e-commerce, et des applications web sur mesure, réalisés pour nos clients." />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="Rock Your Net, créations web, sites vitrines, e-commerce, applications web, développement sur mesure, portfolio, dernières réalisations" />
                <link rel="canonical" href="https://rockyournet.com/Créations" />

                {/* Open Graph pour les réseaux sociaux */}
                <meta property="og:title" content="Nos Dernières Créations | Rock Your Net" />
                <meta property="og:description" content="Explorez nos dernières réalisations en matière de création de sites web et d'applications sur mesure, développés pour nos clients avec expertise." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Créations" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet.png" /> {/* Remplacer par une image réelle */}

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Nos Dernières Créations | Rock Your Net" />
                <meta name="twitter:description" content="Découvrez les dernières créations web et applications sur mesure développées par Rock Your Net pour nos clients." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet.png" /> {/* Remplacer par une image réelle */}

                {/* Schema.org pour les rich snippets */}
                <script type="application/ld+json">
                    {`
        {
            "@context": "https://schema.org",
            "@type": "CreativeWork",
            "name": "Nos Dernières Créations",
            "url": "https://rockyournet.com/Créations",
            "logo": "https://rockyournet.com/static/media/logorockyournet.png",
            "sameAs": [
                "https://www.facebook.com/RockYourNet",
                "https://twitter.com/RockYourNet"
            ],
            "description": "Rock Your Net présente ses dernières créations de sites vitrines, e-commerce, et applications sur mesure pour ses clients.",
            "creator": {
                "@type": "Organization",
                "name": "Rock Your Net",
                "url": "https://rockyournet.com"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+33-6-24-31-10-75",
                "contactType": "Customer Service",
                "areaServed": "FR",
                "availableLanguage": "French"
            }
        }
        `}
                </script>
            </Helmet>


            {/* Structure de la page */}
            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}

            >
                {/* Header (fixe avec Navbar) */}
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                {/* Section principale */}
                <GridItem area={'main'} position="relative" top={{ base: '80px', md: '94px' }}>
                    <MainCréations />
                </GridItem>

                {/* Footer */}
                <GridItem area={'footer'} position="relative" top={{ base: '80px', md: '94px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};
