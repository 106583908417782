import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../common/Navbar';
import MainAPropos from '../sections/MainAPropos';
import Footer from '../common/Footer';
import { Helmet } from 'react-helmet'; // Pour gérer les meta-données dans React

export default function TestPage() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                {/* Titre et Meta description */}
                <title>À propos de Rock Your Net | Expert en Création Web et SEO</title>
                <meta name="description" content="Découvrez Rock Your Net, une entreprise spécialisée dans la création de sites web et l'optimisation SEO. Nous accompagnons les entreprises dans leur développement digital." />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="Rock Your Net, entreprise création web, optimisation SEO, développement web, expert digital, agence web, présence en ligne, stratégie digitale" />
                <link rel="canonical" href="https://rockyournet.com/A-propos" />

                {/* Open Graph pour les réseaux sociaux */}
                <meta property="og:title" content="À propos de Rock Your Net | Expert en Création Web et SEO" />
                <meta property="og:description" content="Rock Your Net est une agence spécialisée dans la création de sites web sur mesure et l'optimisation SEO pour améliorer la présence en ligne de ses clients." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/A-propos" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet.png" /> {/* Remplacer par une image réelle */}

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="À propos de Rock Your Net | Expert en Création Web et SEO" />
                <meta name="twitter:description" content="Rock Your Net aide les entreprises à se développer en ligne grâce à des services de création de sites web et d'optimisation SEO adaptés à leurs besoins." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet.png" /> {/* Remplacer par une image réelle */}

                {/* Schema.org pour les rich snippets */}
                <script type="application/ld+json">
                    {`
        {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Rock Your Net",
            "url": "https://rockyournet.com",
            "logo": "https://rockyournet.com/static/media/logorockyournet.png",
            "sameAs": [
                "https://www.facebook.com/RockYourNet",
                "https://twitter.com/RockYourNet"
            ],
            "description": "Rock Your Net est une entreprise spécialisée dans la création de sites web sur mesure et l'optimisation SEO pour les entreprises souhaitant améliorer leur présence en ligne.",
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+33-6-24-31-10-75",
                "contactType": "Customer Service",
                "areaServed": "FR",
                "availableLanguage": "French"
            }
        }
        `}
                </script>
            </Helmet>


            {/* Structure de la page */}
            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}

            >
                {/* Header (fixe avec Navbar) */}
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                {/* Section principale */}
                <GridItem area={'main'} position="relative" top={{ base: '80px', md: '94px' }}>
                    <MainAPropos />
                </GridItem>

                {/* Footer */}
                <GridItem area={'footer'} position="relative" top={{ base: '80px', md: '94px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};
