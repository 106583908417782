import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    Button,
    Image,
    SimpleGrid,
    useColorModeValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

// Importation des images
import CappadoceImage from '../imagesComponents/CappadoceAccueil.jpg';
import RhoneauImage from '../imagesComponents/RhoneauAccueil.jpg';
import GarageAppImage from '../imagesComponents/GarageAccueil.jpg'; // Remplacer par la vraie image

const creations = [
    {
        title: "Restaurant Cappadoce",
        description: "Un site vitrine moderne pour un restaurant turc à Auxerre.",
        image: CappadoceImage,
        link: "https://www.restaurant-cappadoce.com/",
    },
    {
        title: "Rhôn'eau",
        description: "Un site vitrine pour un plombier à Lyon avec une interface épurée et un SEO optimisé.",
        image: RhoneauImage,
        link: "https://www.rhoneau.fr/",
    },
    {
        title: "Application pour Garages",
        description: "Une application web innovante pour la gestion des véhicules et services des garages.",
        image: GarageAppImage,
        link: "https://garage-parrot.eu/accueil.php",
    },
];

const LastCreations = () => {
    const bg = useColorModeValue('gray.50', 'gray.900');
    const cardBg = useColorModeValue('white', 'gray.700');
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');

    return (
        <Box as="section" py={{ base: '20', md: '160px' }} bg={bg}>
            <Container maxW="7xl" textAlign="center">
                <Heading as="h2" fontSize={{ base: '3xl', md: '4xl' }} fontWeight="bold" color={headingColor} mb={6}>
                    Nos Dernières Créations
                </Heading>
                <Text fontSize="lg" color={textColor} mb={10}>
                    Découvrez quelques-unes de nos dernières réalisations. Cliquez pour en savoir plus sur chacune.
                </Text>

                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10} mb={12}>
                    {creations.map((creation, index) => (
                        <Box
                            key={index}
                            bg={cardBg}
                            shadow="lg"
                            borderRadius="lg"
                            overflow="hidden"
                            transition="transform 0.3s"
                            _hover={{ transform: 'scale(1.05)' }}
                        >
                            <Image src={creation.image} alt={creation.title} objectFit="cover" objectPosition="top" w="100%" h="200px" />
                            <Box p={6}>
                                <Heading as="h3" fontSize="xl" mb={4} color={headingColor}>
                                    {creation.title}
                                </Heading>
                                <Text color={textColor} mb={4}>
                                    {creation.description}
                                </Text>
                                <Button
                                    as={RouterLink}
                                    to={creation.link}
                                    target='blank'
                                    colorScheme="purple"
                                    size="md"
                                    _hover={{
                                        bg: 'white',
                                        color: 'purple.600',
                                        border: '1px solid',
                                        borderColor: 'purple.600',
                                    }}
                                >
                                    Découvrir
                                </Button>
                            </Box>
                        </Box>
                    ))}
                </SimpleGrid>

                <Button
                    as={RouterLink}
                    to="/Créations"
                    colorScheme="purple"
                    size="lg"
                    px={8}
                    py={6}
                    _hover={{
                        bg: 'white',
                        color: 'purple.600',
                        border: '1px solid',
                        borderColor: 'purple.600',
                    }}
                >
                    Découvrir toutes nos créations
                </Button>
            </Container>
        </Box>
    );
};

export default LastCreations;
