import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    SimpleGrid,
    Icon,
    Stack,
    Flex,
    Image,
    Button,
    useColorModeValue,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import MissionImage from '../imagesComponents/Logo_RockYourNet650.webp'; // Assurez-vous que l'image existe
import { Link as RouterLink } from 'react-router-dom';


export default function AboutPage() {
    const bg = useColorModeValue('gray.50', 'gray.900');
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const cardBg = useColorModeValue('white', 'gray.700');

    return (
        <Box as="section" bg={bg} py={12} fontSize="lg">
            {/* Section "À propos de Rock Your Net" */}
            <Container maxW="7xl" py={12}>
                <Heading as="h1" fontSize={{ base: '3xl', md: '4xl' }} fontWeight="bold" textAlign="center" color={headingColor}>
                    À propos de Rock Your Net
                </Heading>
                <Text fontSize="lg" color={textColor} mt={4} textAlign="center">
                    Bienvenue sur Rock Your Net ! Nous sommes là pour vous accompagner dans vos projets numériques
                    et développer votre présence en ligne grâce à des services personnalisés. Que vous ayez besoin d'un site internet attrayant,
                    d'une application performante ou d'un référencement SEO efficace, notre expertise est à votre disposition.
                </Text>
            </Container>

            {/* Section "Qui sommes-nous ?" */}
            <Container maxW="7xl" py={12}>
                <Heading as="h2" fontSize="3xl" fontWeight="bold" textAlign="center" color={headingColor}>
                    Qui sommes-nous ?
                </Heading>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} mt={{ base: '0', md: '10' }}>
                    <Image src={MissionImage} alt="Mission" mt={{ base: '6', md: '14' }} />
                    <Stack spacing={6} fontSize="lg">
                        <Text color={textColor}>
                            Fondée par un passionné du développement informatique, l'entreprise Rock Your Net s’est donnée pour mission de rendre accessible
                            la transformation digitale à toutes les entreprises, qu’elles soient petites ou grandes. Forts de plusieurs années
                            d’expérience dans la création de sites web, nous mettons un point d’honneur à proposer des solutions qui allient
                            innovation, fiabilité et performance.
                        </Text>
                        <Text color={textColor}>
                            Notre objectif est simple : vous offrir une présence en ligne qui reflète l’essence de votre activité et qui attire
                            les bons clients. Nous savons que chaque entreprise est unique, c’est pourquoi nos services sont toujours sur-mesure
                            et adaptés à vos besoins spécifiques.
                        </Text>
                    </Stack>
                </SimpleGrid>
            </Container>

            {/* Section "Nos valeurs" */}
            <Container maxW="7xl" py={12}>
                <Heading as="h2" fontSize="3xl" fontWeight="bold" textAlign="center" color={headingColor} mb={6}>
                    Nos valeurs
                </Heading>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                    <Box
                        p={6}
                        bg={cardBg}
                        borderRadius="lg"
                        shadow="lg"
                        transition="0.3s ease-in-out"
                        _hover={{ transform: 'scale(1.05)' }}
                    >
                        <Flex align="center" mb={4}>
                            <Icon as={CheckIcon} color="purple.500" boxSize={6} mr={2} />
                            <Heading as="h3" fontSize="xl" color={headingColor}>
                                Innovation
                            </Heading>
                        </Flex>
                        <Text color={textColor}>
                            Nous croyons fermement que l'innovation est la clé du succès dans le monde numérique. Nous utilisons les dernières
                            technologies et méthodes pour proposer des solutions avant-gardistes à nos clients.
                        </Text>
                    </Box>
                    <Box
                        p={6}
                        bg={cardBg}
                        borderRadius="lg"
                        shadow="lg"
                        transition="0.3s ease-in-out"
                        _hover={{ transform: 'scale(1.05)' }}
                    >
                        <Flex align="center" mb={4}>
                            <Icon as={CheckIcon} color="purple.500" boxSize={6} mr={2} />
                            <Heading as="h3" fontSize="xl" color={headingColor}>
                                Proximité

                            </Heading>
                        </Flex>
                        <Text color={textColor}>
                            Valeur-clé de notre entreprise, nous nous attachons à offrir un service personnalisé et à entretenir une relation
                            de proximité avec nos clients. Chaque projet est un véritable partenariat.
                        </Text>
                    </Box>
                    <Box
                        p={6}
                        bg={cardBg}
                        borderRadius="lg"
                        shadow="lg"
                        transition="0.3s ease-in-out"
                        _hover={{ transform: 'scale(1.05)' }}
                    >
                        <Flex align="center" mb={4}>
                            <Icon as={CheckIcon} color="purple.500" boxSize={6} mr={2} />
                            <Heading as="h3" fontSize="xl" color={headingColor}>
                                Excellence

                            </Heading>
                        </Flex>
                        <Text color={textColor}>
                            Nous veillons à ce que chaque détail soit optimisé pour offrir la meilleure expérience possible, qu’il s’agisse d’un
                            site internet, d’une application ou d’une stratégie SEO.
                        </Text>
                    </Box>
                    <Box
                        p={6}
                        bg={cardBg}
                        borderRadius="lg"
                        shadow="lg"
                        transition="0.3s ease-in-out"
                        _hover={{ transform: 'scale(1.05)' }}
                    >
                        <Flex align="center" mb={4}>
                            <Icon as={CheckIcon} color="purple.500" boxSize={6} mr={2} />
                            <Heading as="h3" fontSize="xl" color={headingColor}>
                                Transparence

                            </Heading>
                        </Flex>
                        <Text color={textColor}>
                            Nous croyons en une communication ouverte et honnête avec nos clients. Vous serez informé de chaque étape du projet.

                        </Text>
                    </Box>
                </SimpleGrid>
            </Container>

            {/* Appel à l'action */}
            <Container maxW="7xl" py={12} textAlign="center">
                <Heading as="h3" fontSize="2xl" color={headingColor} mb={4}>
                    Prêt à démarrer votre projet ?
                </Heading>
                <Button
                    as={RouterLink}  // Utilisez Link de react-router-dom
                    to="/Contact"  // Spécifiez la route vers laquelle vous voulez rediriger
                    colorScheme="purple"
                    size="lg"
                    mb={10}
                    _hover={{
                        bg: 'white',
                        color: 'purple.600',
                        border: '1px solid',
                        borderColor: 'purple.600',
                    }}
                >
                    Contactez-nous dès aujourd'hui
                </Button>
            </Container>
        </Box>
    );
}
