import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    SimpleGrid,
    Icon,
    Stack,
    Flex,
    Button,
    useColorModeValue,
    Image,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import AppImage from '../imagesComponents/applicationWeb.webp'; // Assurez-vous que l'image existe
import Logo from '../imagesComponents/Logo_RockYourNet650.webp';
import { Link as RouterLink } from 'react-router-dom';



// Fonctionnalités du service de développement d'applications
const features = [
    { id: 1, title: "Applications Web", description: "Nous développons des applications web modernes, performantes et parfaitement adaptées à vos besoins spécifiques." },
    { id: 2, title: "Applications Mobiles", description: "Profitez de notre expertise pour créer des applications mobiles natives et hybrides qui répondent aux besoins de vos utilisateurs." },
    { id: 3, title: "Interface utilisateur intuitive", description: "Nos applications sont conçues pour offrir une expérience utilisateur fluide, avec des interfaces intuitives et réactives." },
    { id: 4, title: "Maintenance et évolution", description: "Nous vous accompagnons tout au long du cycle de vie de votre application pour assurer son évolution et sa maintenance." },
];

export default function ApplicationWebPage() {
    const bg = useColorModeValue('gray.50', 'gray.900');
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const cardBg = useColorModeValue('white', 'gray.700');

    return (
        <Box as="section" py={10} bg={bg} minH="100vh">
            {/* Hero section */}
            <Container maxW="7xl" py={12}>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} alignItems="center">
                    <Stack spacing={6}>
                        <Heading
                            lineHeight={1.2}
                            fontWeight="bold"
                            fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
                            color={headingColor}
                        >
                            Développez votre application avec <br />
                            <Flex justifyContent="center" alignItems="center">
                                <Image src={Logo} alt="Logo Rock Your Net" width={{ base: '150px', md: '280px' }} mt={5} ml={3} />
                            </Flex>
                        </Heading>
                        <Text color={textColor} fontSize="lg">
                            Que ce soit pour une application web ou mobile, notre équipe d'experts vous accompagne dans la création de solutions sur mesure,
                            adaptées à vos besoins professionnels. Faites confiance à Rock Your Net pour un développement performant, sécurisé et évolutif.
                        </Text>
                        <Flex justifyContent="center" alignItems="center">
                            <Button
                                as={RouterLink}  // Utilisez Link de react-router-dom
                                to="/Contact"  // Spécifiez la route vers laquelle vous voulez rediriger
                                colorScheme="purple"
                                size="lg"
                                width="200px"
                                mb={10}
                                _hover={{
                                    bg: 'white',
                                    color: 'purple.600',
                                    border: '1px solid',
                                    borderColor: 'purple.600',
                                }}
                            >
                                Obtenez un devis
                            </Button>
                        </Flex>
                    </Stack>
                    <Flex justifyContent="center" alignItems="center">
                        <Image
                            src={AppImage}
                            alt="Développement d'application"
                            boxSize={{ base: '300px', md: '450px' }}
                            objectFit="cover"
                            borderRadius="md"
                            shadow="lg"
                        />
                    </Flex>
                </SimpleGrid>
            </Container>

            {/* Section des fonctionnalités */}
            <Container maxW="7xl" py={12}>
                <Heading as="h2" fontSize="3xl" mb={10} textAlign="center" color={headingColor}>
                    Pourquoi choisir Rock Your Net pour le développement d'applications ?
                </Heading>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
                    {features.map(feature => (
                        <Box
                            key={feature.id}
                            p={6}
                            bg={cardBg}
                            borderRadius="lg"
                            shadow="lg"
                            _hover={{ transform: 'scale(1.05)', transition: '0.3s ease-in-out' }}
                        >
                            <Flex align="center" mb={4}>
                                <Icon as={CheckIcon} color="purple.500" boxSize={6} mr={2} />
                                <Heading as="h3" fontSize="xl" color={headingColor}>
                                    {feature.title}
                                </Heading>
                            </Flex>
                            <Text color={textColor}>
                                {feature.description}
                            </Text>
                        </Box>
                    ))}
                </SimpleGrid>
            </Container>

            {/* Appel à l'action */}
            <Container maxW="7xl" py={12} textAlign="center">
                <Heading as="h3" fontSize="2xl" color={headingColor} mb={4}>
                    Vous avez un projet d'application ?
                </Heading>
                <Button
                    as={RouterLink}  // Utilisez Link de react-router-dom
                    to="/Contact"  // Spécifiez la route vers laquelle vous voulez rediriger
                    colorScheme="purple"
                    size="lg"
                    mb={10}
                    _hover={{
                        bg: 'white',
                        color: 'purple.600',
                        border: '1px solid',
                        borderColor: 'purple.600',
                    }}
                >
                    Nous contacter
                </Button>
            </Container>
        </Box>
    );
}
