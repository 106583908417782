import React, { useState } from 'react';
import {
    Box,
    Link,
    Button,
    Container,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Stack,
    Flex,
    Text,
    useToast,
    useColorModeValue,
} from '@chakra-ui/react';
import emailjs from 'emailjs-com';
import Logo from '../imagesComponents/RockYourNet.webp';


export default function ContactPage() {
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const toast = useToast();

    // Gérer les changements dans le formulaire
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    // Soumission du formulaire
    const handleSubmit = (e) => {
        e.preventDefault();

        // Envoyer l'email avec EmailJS
        emailjs.sendForm('RockYourNet', 'RockYourNet', e.target, '59cn7LXxMiOG7vw2j')
            .then((result) => {
                // Afficher un toast de succès
                toast({
                    title: 'Message envoyé.',
                    description: "Votre message a bien été envoyé. Nous vous répondrons dès que possible.",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                setFormData({ name: '', email: '', message: '' }); // Réinitialiser le formulaire
            }, (error) => {
                // Afficher un toast d'erreur
                toast({
                    title: 'Erreur.',
                    description: "Une erreur s'est produite. Veuillez réessayer.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            });
    };

    const bg = useColorModeValue('gray.50', 'gray.900');
    const inputBg = useColorModeValue('white', 'gray.700');
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');

    return (
        <Box as="section" h="auto" bg={bg}
            py={{ base: '20', md: '200px' }}
            mt={{ base: '16', md: '0' }}
            backgroundImage={`url(${Logo})`}  // Ajout de l'image en fond
            backgroundSize="cover"  // Assure que l'image couvre toute la section
            backgroundPosition="center"  // Centre l'image
            backgroundRepeat="no-repeat"  // Empêche la répétition
            position="relative"
            _before={{
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: useColorModeValue('rgba(255, 255, 255, 0.85)', 'rgba(0, 0, 0, 0.6)'), // Superposition selon le mode clair ou sombre
                zIndex: 1,  // Assure que l'overlay est derrière le contenu
            }}>
            {/* Header */}
            <Container maxW="7xl" textAlign="center" mb={12} position="relative" zIndex={2}>
                <Heading as="h1" fontSize={{ base: '3xl', md: '4xl' }} fontWeight="bold" color={headingColor}>
                    Contactez-nous
                </Heading>
                <Text fontSize="lg" color={textColor} mt={4}>
                    Nous sommes disponibles pour répondre à toutes vos questions. Remplissez le formulaire ci-dessous pour nous contacter.
                </Text>
            </Container>

            <Container maxW="5xl" position="relative" zIndex={2}>
                <Flex justify="center" align="center">
                    <Box w={{ base: 'full', md: '70%' }} p={6} boxShadow="lg" borderRadius="lg" bg={inputBg}>
                        <form onSubmit={handleSubmit}>
                            <Stack spacing={6} color={headingColor}>
                                <FormControl id="name" isRequired >
                                    <FormLabel>Nom</FormLabel>
                                    <Input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </FormControl>

                                <FormControl id="email" isRequired>
                                    <FormLabel>Email</FormLabel>
                                    <Input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </FormControl>

                                <FormControl id="phone" isRequired>
                                    <FormLabel>Téléphone</FormLabel>
                                    <Input
                                        type="tel"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                </FormControl>

                                <FormControl id="message" isRequired>
                                    <FormLabel>Message</FormLabel>
                                    <Textarea
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                    />
                                </FormControl>

                                <Button type="submit" colorScheme="purple" size="lg">
                                    Envoyer
                                </Button>
                            </Stack>
                        </form>
                    </Box>
                </Flex>
            </Container>

            {/* Appel à l'action */}
            <Container maxW="7xl" mt={12} textAlign="center" position="relative" zIndex={2}>
                <Heading as="h3" fontSize="2xl" color={headingColor} mb={4}>
                    Vous préférez nous appeler ?
                </Heading>
                <Text fontSize="lg" color={textColor}>
                    Appelez-nous au
                    <Text as="span" color="purple.500">
                        <Link href="tel:+33624311075" mx={2}>06 24 31 10 75</Link>
                    </Text>
                    ou envoyez-nous un email à
                    <Text as="span" color="purple.500">
                        <Link href="mailto:contact@rockyournet.com" ml={2}>contact@rockyournet.com</Link>
                    </Text>
                </Text>
            </Container>
        </Box>
    );
}
