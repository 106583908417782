import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    List,
    ListItem,
    Link,
    useColorModeValue,
    Stack, // Ajout de Stack pour le spacing
} from '@chakra-ui/react';

const LegalMentions = () => {
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');

    return (
        <Box as="section" py={12}>
            <Container maxW="7xl">
                <Stack spacing={16}> {/* Espacement entre les sections */}
                    <Box>
                        <Heading as="h1" fontSize="3xl" mb={6} color={headingColor}>
                            Mentions Légales
                        </Heading>

                        <Text fontSize="lg" color={textColor}>
                            En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, il est précisé aux utilisateurs du site <strong>Rock Your Net</strong> l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi.
                        </Text>
                    </Box>

                    {/* Section éditeur du site */}
                    <Box>
                        <Heading as="h2" fontSize="2xl" mb={4} color={headingColor}>
                            1. Présentation du site
                        </Heading>
                        <List spacing={3} color={textColor}>
                            <ListItem><strong>Propriétaire :</strong> RockYourNet – SIRET 93238667500015 – 13 Rue de l'Abbé Grégoire, 89290 Escolives-Sainte-Camille, France</ListItem>
                            <ListItem><strong>Créateur :</strong> Colas Vincendon </ListItem>
                            <ListItem><strong>Responsable publication :</strong> Colas Vincendon – contact@rockyournet.com</ListItem>
                            <ListItem><strong>Webmaster :</strong> Colas Vincendon – contact@rockyournet.com</ListItem>
                            <ListItem><strong>Hébergeur :</strong> O2Switch – 222 Boulevard Gustave Flaubert, 63000 Clermont-Ferrand, France – Téléphone : <Link href="tel:+33444446040">+33 4 44 44 60 40</Link></ListItem>
                        </List>
                    </Box>

                    {/* Conditions générales */}
                    <Box>
                        <Heading as="h2" fontSize="2xl" mb={4} color={headingColor}>
                            2. Conditions générales d’utilisation du site et des services proposés
                        </Heading>
                        <Text fontSize="lg" color={textColor}>
                            L’utilisation du site <strong>www.rockyournet.com</strong> implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs du site sont donc invités à les consulter de manière régulière.
                        </Text>
                    </Box>

                    {/* Services fournis */}
                    <Box>
                        <Heading as="h2" fontSize="2xl" mb={4} color={headingColor}>
                            3. Description des services fournis
                        </Heading>
                        <Text fontSize="lg" color={textColor}>
                            Le site <strong>www.rockyournet.com</strong> a pour objet de fournir une information concernant l’ensemble des activités de la société. Rock Your Net s’efforce de fournir sur le site des informations aussi précises que possible mais ne saurait être tenu responsable des omissions, inexactitudes et carences dans la mise à jour.
                        </Text>
                    </Box>

                    {/* Propriété intellectuelle */}
                    <Box>
                        <Heading as="h2" fontSize="2xl" mb={4} color={headingColor}>
                            4. Propriété intellectuelle et contrefaçons
                        </Heading>
                        <Text fontSize="lg" color={textColor}>
                            Rock Your Net est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site. Toute reproduction, modification ou adaptation de tout ou partie du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable.
                        </Text>
                    </Box>

                    {/* Limitations de responsabilité */}
                    <Box>
                        <Heading as="h2" fontSize="2xl" mb={4} color={headingColor}>
                            5. Limitations de responsabilité
                        </Heading>
                        <Text fontSize="lg" color={textColor}>
                            Rock Your Net ne pourra être tenu responsable des dommages directs ou indirects causés au matériel de l’utilisateur lors de l’accès au site <strong>www.rockyournet.com</strong>.
                        </Text>
                    </Box>

                    {/* Données personnelles */}
                    <Box>
                        <Heading as="h2" fontSize="2xl" mb={4} color={headingColor}>
                            6. Gestion des données personnelles
                        </Heading>
                        <Text fontSize="lg" color={textColor}>
                            Rock Your Net ne collecte des informations personnelles relatives à l’utilisateur que pour le besoin de certains services proposés par le site. Conformément à la loi 78-17 du 6 janvier 1978 relative à l'informatique et aux libertés, l'utilisateur dispose d’un droit d'accès, de rectification et d'opposition aux données personnelles le concernant.
                        </Text>
                    </Box>

                    {/* Liens et cookies */}
                    <Box>
                        <Heading as="h2" fontSize="2xl" mb={4} color={headingColor}>
                            7. Liens hypertextes et cookies
                        </Heading>
                        <Text fontSize="lg" color={textColor}>
                            La navigation sur le site <strong>www.rockyournet.com</strong> est susceptible de provoquer l’installation de cookie(s) sur l’ordinateur de l’utilisateur. L’utilisateur peut refuser l’installation des cookies en modifiant les paramètres de son navigateur.
                        </Text>
                    </Box>

                    {/* Droit applicable */}
                    <Box>
                        <Heading as="h2" fontSize="2xl" mb={4} color={headingColor}>
                            8. Droit applicable et attribution de juridiction
                        </Heading>
                        <Text fontSize="lg" color={textColor}>
                            Tout litige en relation avec l’utilisation du site <strong>www.rockyournet.com</strong> est soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Lyon.
                        </Text>
                    </Box>

                </Stack>
            </Container>
        </Box>
    );
};

export default LegalMentions;
