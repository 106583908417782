import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    SimpleGrid,
    Icon,
    Stack,
    Flex,
    Button,
    useColorModeValue,
    Image,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import CatalogueImage from '../imagesComponents/catalogue.webp'; // Assurez-vous que l'image existe
import Logo from '../imagesComponents/Logo_RockYourNet650.webp';
import { Link as RouterLink } from 'react-router-dom';



// Fonctionnalités du service Site Catalogue
const features = [
    { id: 1, title: "Présentation claire des produits", description: "Créez un catalogue structuré et attrayant pour présenter vos produits de manière efficace." },
    { id: 2, title: "Facilité de navigation", description: "Votre catalogue est optimisé pour permettre aux utilisateurs de trouver rapidement ce qu'ils recherchent." },
    { id: 3, title: "Design responsive", description: "Nous garantissons que votre catalogue sera parfaitement lisible sur tous les appareils, qu'il s'agisse de smartphones, tablettes ou ordinateurs." },
    { id: 4, title: "Optimisation SEO", description: "Améliorez le référencement naturel de votre catalogue pour qu'il soit bien positionné dans les moteurs de recherche." },
];

export default function SiteCatalogue() {
    const bg = useColorModeValue('gray.50', 'gray.900');
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const cardBg = useColorModeValue('white', 'gray.700');

    return (
        <Box as="section" py={10} bg={bg} minH="100vh">
            {/* Hero section */}
            <Container maxW="7xl" py={12}>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} alignItems="center">
                    <Stack spacing={6}>
                        <Heading
                            lineHeight={1.2}
                            fontWeight="bold"
                            fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
                            color={headingColor}
                        >
                            Présentez votre catalogue en ligne avec <br />
                            <Flex justifyContent="center" alignItems="center">
                                <Image src={Logo} alt="Logo Rock Your Net" width={{ base: '150px', md: '280px' }} mt={5} ml={3} />
                            </Flex>
                        </Heading>
                        <Text color={textColor} fontSize="lg">
                            Nous créons des sites catalogue élégants et performants qui vous permettent de présenter vos produits en ligne de manière professionnelle.
                            Votre catalogue sera à la fois facile à naviguer et optimisé pour attirer plus de clients.
                        </Text>
                        <Flex justifyContent="center" alignItems="center">
                            <Button
                                as={RouterLink}  // Utilisez Link de react-router-dom
                                to="/Contact"  // Spécifiez la route vers laquelle vous voulez rediriger
                                colorScheme="purple"
                                size="lg"
                                width="200px"
                                mb={10}
                                _hover={{
                                    bg: 'white',
                                    color: 'purple.600',
                                    border: '1px solid',
                                    borderColor: 'purple.600',
                                }}
                            >
                                Obtenez un devis
                            </Button>
                        </Flex>
                    </Stack>
                    <Flex justifyContent="center" alignItems="center">
                        <Image
                            src={CatalogueImage}
                            alt="Site catalogue"
                            boxSize={{ base: '300px', md: '450px' }}
                            objectFit="cover"
                            borderRadius="md"
                            shadow="lg"
                        />
                    </Flex>
                </SimpleGrid>
            </Container>

            {/* Section des fonctionnalités */}
            <Container maxW="7xl" py={12}>
                <Heading as="h2" fontSize="3xl" mb={10} textAlign="center" color={headingColor}>
                    Pourquoi choisir un site catalogue avec Rock Your Net ?
                </Heading>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
                    {features.map(feature => (
                        <Box
                            key={feature.id}
                            p={6}
                            bg={cardBg}
                            borderRadius="lg"
                            shadow="lg"
                            _hover={{ transform: 'scale(1.05)', transition: '0.3s ease-in-out' }}
                        >
                            <Flex align="center" mb={4}>
                                <Icon as={CheckIcon} color="purple.500" boxSize={6} mr={2} />
                                <Heading as="h3" fontSize="xl" color={headingColor}>
                                    {feature.title}
                                </Heading>
                            </Flex>
                            <Text color={textColor}>
                                {feature.description}
                            </Text>
                        </Box>
                    ))}
                </SimpleGrid>
            </Container>

            {/* Appel à l'action */}
            <Container maxW="7xl" py={12} textAlign="center">
                <Heading as="h3" fontSize="2xl" color={headingColor} mb={4}>
                    Prêt à lancer votre site catalogue ?
                </Heading>
                <Button
                    as={RouterLink}  // Utilisez Link de react-router-dom
                    to="/Contact"  // Spécifiez la route vers laquelle vous voulez rediriger
                    colorScheme="purple"
                    size="lg"
                    mb={10}
                    _hover={{
                        bg: 'white',
                        color: 'purple.600',
                        border: '1px solid',
                        borderColor: 'purple.600',
                    }}
                >
                    Nous contacter
                </Button>
            </Container>
        </Box>
    );
}
